import { graphql, Link } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import ContactForm from "../components/contactForm/form";
import Features from "../components/features/features";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import ImageText from "../components/imageText/imageText";
import Seo from "../components/seo";
import SlickArrowLeft from "../components/SlickArrowLeft";
import SlickArrowRight from "../components/slickArrowRight";
import Testimonials from "../components/testimonials/testimonials";
import Title from "../components/title/title";
import Top from "../components/top/top";
import iconAdv1 from "../images/CustomerStoriesKabob/icon-adv-1.png";
import iconAdv2 from "../images/CustomerStoriesKabob/icon-adv-2.png";
import iconAdv3 from "../images/CustomerStoriesKabob/icon-adv-3.png";
import gradientBg from "../images/OrdersConsolidation/gradient-pink-bg.png";
import YoutubeVideo from './../components/youtubeVideo/youtubeVideo';

// markup
const CustomerStoriesKabobPage = ({ location, data }) => {
  const formRef = React.useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const topProps = {
    layout: 2,
    title: <>At Orders.co we treat our customers as our partners.</>,
    hideLine: true,
    subtitle: ``,
    scrollToForm: scrollToForm,
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="customers"
        src="../images/CustomerStoriesKabob/customers.png"
        className="mw-100"
      />
    ),
  };

  const testimonials = [
    {
      image: (
        <StaticImage
          placeholder="none"
          loading="eager"
          src="../images/CustomerStoriesKabob/Suzy.png"
          alt="Suzy Antonyan"
          className="mw-100 w-100"
        />
      ),
      text: `I love how the work process became smoother after we started working with Orders.co.
                One of the coolest features is that you can edit and customize your online menu with a
                touch of a few buttons and it’s ready to go.`,
      author: "Suzy Antonyan",
      position: "Manager at Johna’s Kabob",
    },
  ];

  const testimonialsProps = {
    layout: 5,
    items: testimonials,
    title: <span className="text-left d-block">Jonah's Kabob</span>,
    tag: "",
  };

  const features = [
    {
      bg: "#DEFFEE",
      icon: iconAdv1,
      title: "One Device",
      description: `Free staff from the stress of managing multiple tablets with Orders.co consolidation.
                All online ordering platforms in one user-friendly device.`,
    },
    {
      bg: "#F1F7FF",
      icon: iconAdv2,
      title: "One Order List",
      description: `Make servicing hours a breeze with all brands, concepts, and platforms in one unified ordering list.`,
    },
    {
      bg: "#FFF7E3",
      icon: iconAdv3,
      title: "Reporting",
      description: `Use tools like top selling items, sales by provider, and summary reports to make
                data-driven decision and optimize all online ordering channels.`,
    },
  ];

  const imageTextProps1 = {
    layout: "equal-big",
    title: "Customers Situation",
    titleStyle: "",
    imagePosition: "left",
    description: (
      <p style={{ fontSize: "22px" }}>
        Jonah’s Kabob is a newer restaurant established to offer customers
        classic mediterranean food with a modern convenient twist. After
        building a simple menu with quality ingredients, they set out to grow a
        customer base. From previous experience Suzy knew that delivery apps and
        online ordering could be of great help here.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="delivery"
        src="../images/CustomerStoriesKabob/tablet-delivery.png"
      />
    ),
  };

  const imageTextProps2 = {
    layout: "equal-big",
    title: "The Challenge",
    titleStyle: "",
    imagePosition: "right",
    description: (
      <p style={{ fontSize: "22px" }}>
        After a few weeks of managing orders and menus across multiple delivery
        platforms, it became clear to Suzy that something had to be done. They
        were spending excessive amounts of time just to add one new menu item
        across all these platforms and kept discovering inconsistencies in their
        menus. The lack of a direct ticket printer for orders was also delaying
        the food preparation process which caused issues with drivers.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="challenge"
        src="../images/CustomerStoriesKabob/challenge.png"
      />
    ),
  };

  const imageTextProps3 = {
    layout: "equal-big",
    title: "Orders.co Solution",
    titleStyle: "",
    imagePosition: "left",
    description: (
      <p style={{ fontSize: "22px" }}>
        After partnering with Orders.co, Suzy signed up for the Premium Plan.
        Integrating all of Jonah’s delivery apps into one platform. Allowing for
        easy order management and immediate ticket printing with a standardized
        format. Menus were consolidated into one uniform Master Menu; completely
        eliminating the need to access each provider individually. Their Master
        Menu was also used to create a commission-free ordering website to
        generate additional revenue.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="orders.co solution"
        src="../images/CustomerStoriesKabob/Orders.co-Solution.png"
      />
    ),
  };

  const imageTextProps4 = {
    layout: "equal-big",
    title: "The Outcome",
    titleStyle: "",
    imagePosition: "right",
    description: (
      <p style={{ fontSize: "22px" }}>
        Cooperation with Orders.co made Slash Pizza’s commission-free website
        the top revenue generator in just 3 months. With a total revenue
        increase of 73% across all online ordering. Armond has shifted his
        attention to promoting his website and primarily uses delivery apps for
        new customer acquisition and marketing.
      </p>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="outcome"
        src="../images/CustomerStoriesKabob/outcome.png"
      />
    ),
  };

  const features2 = [
    {
      bg: "#ff6b0033",
      number: 1,
      title: "",
      description: `Jonah’s was a new restaurant just starting out. Thus, focusing on their
                food & menu were of the highest priority. They needed a solution that allowed them
                to manage their online ordering easily so they could get their business off the
                ground running.`,
    },
    {
      bg: "#ff6b0033",
      number: 2,
      title: "",
      description: `While at a previous employer, a manager of Jonah’s was exposed to the
                Orders.co system. He quickly realized how easy it was to use and the time it
                would save them managing online ordering. He vowed the next restaurant he worked
                in would use Orders.co.`,
    },
    {
      bg: "#ff6b0033",
      number: 3,
      title: "",
      description: `Thus, Jonah’s Kabob was born and from it’s earliest days, Orders.co
                facilitated their online ordering. With a uniform Master Menu, Jonah’s was able
                to expand from 1 to 4 delivery app providers and increase their revenue by 40%
                in 1 month. All while only having to maintain one platform for their orders,
                menus, and reports.`,
    },
  ];

  const sliderSettings = {
    dots: false,
    arrows: false,
    slidesToShow: 2,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 767.9,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Seo
        title={"Customer Stories Johna's Kabob"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <Top {...topProps} />
        <Testimonials {...testimonialsProps} />
        <Features title="Top Benefits" items={features} />
        <div
          className="bg-desktop pb-0 pb-md-5 mb-0"
          style={{
            backgroundImage: `url(${gradientBg})`,
            backgroundSize: `100% 100%`,
          }}
        >
          <YoutubeVideo
            videoId={"8iuMePnkOkQ"}
            image={<StaticImage
              placeholder="none"
              loading="eager"
              alt="Suzy Antonyan"
              className="mw-100 w-100 mh-100"
              src="../images/CustomerStoriesKabob/Suzy-Antonyan.jpg"
            />}
          ></YoutubeVideo>
          <Title>
            <b
              className="mt-5 mt-md-0"
              style={{ fontSize: "42.861px", lineHeight: "64px" }}
            >
              All Your Online Orders in One Device
            </b>
          </Title>
          <ImageText {...imageTextProps1} />
          <ImageText {...imageTextProps2} />
          <ImageText {...imageTextProps3} />
          <ImageText {...imageTextProps4} />
        </div>
        <div
          className="d-none d-xl-block"
          style={{ marginBottom: "183px" }}
        ></div>
        <Features
          title={
            <span className="text-center d-block">
              Johna's Kabob express was struggling maintaining 3 separate
              tablets with limited counterspace.
            </span>
          }
          items={features2}
        />
        <Title>
          <b
            className="text-center text-lg-left d-block"
            style={{ fontSize: "42.861px", lineHeight: "64px" }}
          >
            From Local Diners to Multinational Chains
          </b>
        </Title>
        <div className="container mb-3 mb-lg-5 pb-3 pb-lg-5">
          <Slider {...sliderSettings}>
            <div className="col-12">
              <div className="post-item d-flex flex-column">
                <div className="d-flex justify-content-center">
                  <StaticImage
                    placeholder="blurred"
                    alt="Independent Restaurants"
                    src="../images/CustomerStoriesKabob/slider-img-1.png"
                    className="mw-100"
                  />
                </div>

                <Link to="/independent-restaurants/" className="post-title">
                  Independent Restaurants
                </Link>
                <div className="post-description">
                  Orders.co was built from the ground up to support restaurants
                  of varying sizes. Our tools help cut costs.
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="post-item d-flex flex-column">
                <div className="d-flex justify-content-center">
                  <StaticImage
                    placeholder="none"
                    loading="eager"
                    alt="Chain Restaurants"
                    src="../images/CustomerStoriesKabob/slider-img-2.png"
                    className="mw-100"
                  />
                </div>
                <Link to="/chain-restaurants/" className="post-title">
                  Chain Restaurants
                </Link>
                <div className="post-description">
                  The Orders.co suite was built to facilitate chain restaurants'
                  needs like never before.
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div ref={formRef}>
          <ContactForm></ContactForm>
        </div>
      </main>
      <Footer></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "customer-stories-jonahs-kabob" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
  }
`;

export default CustomerStoriesKabobPage;
